.server-down-page {
    text-align: center;
    padding: 50px;
    background: #344767;
    color: white;
    height: 100vh;
  }
  
  .server-down-page h1 {
    font-size: 2rem;
  }
  
  .server-down-page button {
    background: white;
    color: red;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }
  