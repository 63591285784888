/* Scrollbar Track */
.scroll-column::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  border-top-right-radius: 2px;
}

/* Scrollbar Thumb */
.scroll-column::-webkit-scrollbar-thumb {
  background-color: #bbb; /* Color of the scrollbar */
  border-radius: 4px; /* Rounds the corners of the scrollbar */
  border-top-right-radius: 2px;
}

/* Scrollbar Thumb Hover */
.scroll-column::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Darker color on hover */
  border-top-right-radius: 2px;
}

/* Scrollbar Track */
.scroll-column::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the scrollbar track */
  border-top-right-radius: 2px;
}
.scroll-column {
  height: 80vh; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x:hidden;
  border-top-right-radius: 2px;
}


.table-text {
      opacity: 0.8;
      color: rgba(255, 255, 255, 1);
      // font-family: "Helvetica";
      font-size: 12px;
      font-weight: 100;
      font-style: Regular;
      letter-spacing: 0.08px;
      text-align: left;
    
      padding: 12px 7px;
      vertical-align: middle;
}
.table-text > th{

  opacity: 0.8;
  color: rgba(255, 255, 255, 1);
  // font-family: "Helvetica";
  font-size: 12px;
  font-weight: 100;
}



.input-group-text {
  background-color: transparent; /* Makes the background of the icon area transparent */
  border: none; /* Removes any border around the icon */
}

.input-group .form-control {
  border-radius: 8px ; /* Adjust to match your design */
  padding-left: 2.5rem; /* Adds padding to avoid text overlapping with the icon */
}
.input-white-placeholder::placeholder {
  
  color: white !important; /* Sets placeholder color to white */
  opacity: .8; /* Ensures full opacity for the placeholder text */
  text-align: 'left';
  padding-left: 1 !important;
  margin-left: 1 !important;
}

.input-white-text {
  opacity: .8;
  color: white !important; 
  border-top-left-radius: 8px !important;/* Sets the input text color to white */
  text-align: left !important;
  padding-left: "2px" !important;

}
 
.input-gray-placeholder::placeholder {
color: $dark-gray !important; /* Sets placeholder color to white */
opacity: .8; /* Ensures full opacity for the placeholder text */
text-align: 'left';
padding-left: 1 !important;
margin-left: 1 !important;
border-color: $dark-gray !important;
}

.input-gray-text {
opacity: .8;
color: $dark-gray !important; 
border-top-left-radius: 8px !important;/* Sets the input text color to white */
text-align: left !important;
padding-left: "2px" !important;
border-color: $dark-gray !important;

}
.input-group-append{
  border-color:$dark-gray !important;
}
.form-group .form-control + .input-group-prepend .input-group-text, .form-group .form-control + .input-group-append .input-group-text, .input-group .form-control + .input-group-prepend .input-group-text, .input-group .form-control + .input-group-append .input-group-text{
  border-color:$dark-gray;
  margin-right: 10px
}

.document{
  height: 200px;
}

.input-group-text i {
  color: white; /* Change the color of the icon to match your design */
  opacity: .8;
}

h6{
  color:'white' !important;
  font-size:"17px" !important;
  letter-spacing:'.11';
  font-weight:200;
}

.title{
  color: "white" !important;
}


.text-main1{
  color:'white' !important;
  font-size:"17px";
  letter-spacing:'.11';
  font-weight:100;
}

.timeline-white-text{
  color: white !important;
}

.timeline-black-text{
  color: #444444 !important;
}

// .search-input {
//   border-radius: 20px !important; /* or 0px if you want no rounding */
// }
.card.card-timeline{
  .card-body {
    padding-left: 0;
    padding-right: 0;

  }
    .timeline {
        list-style: none;
        padding: 10px 0 20px;
        position: relative;

        &:before {
            top: 0;
            bottom: 0;
            position: absolute;
            content: " ";
            width: 3px;
            background-color: $black;
            left: 5%;
            margin-left: -1.5px;
        }

        .timeline-footer {
          .btn{
            margin: 0;

            &.dropdown-toggle {
              i {
                top: -1px;
                left: 10px;
              }

              &:after {
                margin-left: 20px !important;
              }
            }
          }
        }

        h6{
            color: rgba($white,0.8);
            font-weight: 400;
            margin: 10px 0px 0px;
        }

        &.timeline-simple {

            &:before {
                left: 5%;
            }

            > li > .timeline-panel {
                width: 86%;
            }

            > li > .timeline-badge {
                left: 5%;
            }
        }

        > li {
            margin-bottom: 20px;
            position: relative;

            &:before,
            &:after {
                content: " ";
                display: table;
            }

            &:after {
                clear: both;
            }

        }

        > li > .timeline-panel {
            background: linear-gradient(to bottom, #F6F6F6 0%, #EAEAEA 100%) !important;;
            width: 80%;
            float: left;
            left: 18%;
            margin-right: 10px;
            padding: 20px;
            border-radius: $border-radius-sm;
            box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
            color: black !important;
            margin-bottom: 20px;
            position: relative;

            &:before {
                position: absolute;
                top: 10px;
                right: -15px;
                display: inline-block;
                border-top: 15px solid transparent;
                // border-left: 15px solid $black;
                border-right: 0 solid $black;
                border-bottom: 15px solid transparent;
                content: " ";
            }

            &:after {
                position: absolute;
                top: 27px;
                right: -14px;
                display: inline-block;
                border-top: 14px solid transparent;
                // border-left: 14px solid $card-black-background;
                // border-right: 0 solid $card-black-background;
                border-bottom: 14px solid transparent;
                content: " ";
            }

            &.timeline-panel-white{
              background: #1CB7D1;
              .timeline-body p{
                color: whitesmoke;
              }

              &:after, &:before{
                border-right-color: #1CB7D1;
                border-left-color: #1CB7D1;
              }
            }
        }



        > li > .timeline-badge {
            color: $white;
            width: 50px;
            height: 50px;
            line-height: 51px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: 16px;
            left: 5%;
            margin-left: -25px;
            background-color: $dark-gray;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;

            [class^="ti-"],
            [class*=" ti-"]{
                line-height: inherit;
            }

            .tim-icons{
              width: 25px;
              height: 21px;
              text-align: center;
            }
        }



        > li.timeline-inverted > .timeline-panel {
            background: linear-gradient(to bottom, #1CB7D1 0%, #1E92B6 100%) !important;
            float: left;
            left:12%;

            &:before {
                // position: absolute;
                top: 25px;
                // right: -14px;
                // display: inline-block;
                border-top: 14px solid transparent;
                // // border-left: 14px solid $card-black-background;
                border-right: 15px solid #19AECA !important;
                // border-bottom: 14px solid transparent;
                // content: " ";
  
               
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }

            &:after {
                // border-right: 14px solid #1CB7D1 !important;
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }

        }


    }
    .timeline-heading{
        margin-bottom: 15px;
    }
    .timeline-badge{
      background-color: #19AECA !important; 
    }
    .timeline-badge.primary {
      background-color: $info !important;
    }
    .timeline-badge.info {
      background-color: $info !important;
    }

    .timeline-badge.success {
      background-color: darken($success,10%) !important;
    }

    .timeline-badge.warning {
      background-color: $warning !important;
    }

    .timeline-badge.danger {
      background-color: $danger !important;
    }


    .timeline-title {
      margin-top: 0;
      color: inherit;
    }

    .timeline-body > p,
    .timeline-body > ul {
      margin-bottom: 0;
      color: "white";
    }

    .timeline-body > p + p {
      margin-top: 5px;
    }



}

@include media-breakpoint-down(sm){
  .card.card-timeline {
    .timeline {
        &:before {
          left: 5%;
        }

        > li > .timeline-badge {
            left: 5%;
        }

        > li > .timeline-panel{
            float: right;
            width: 83% !important;

            &:before{
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto;
            }

            &:after{
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto;
            }
        }
    }
  }
}
